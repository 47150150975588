import axios from 'axios';
import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', async function() {

    function snitcherCallback( identification ) {
        // We want to ignore 'isp' type identifications
        // console.log(identification);
        if (identification && identification.type !== "isp") {

            // The company was successfully identified!
            const company = identification.company;

            const domain         = company.domain;
            const branch         = company.name;
            
            let employeesRange = company.employee_range;  
            let min            = 0;
            let max            = 0;

            if( employeesRange ) {

                employeesRange = employeesRange.split(' ');
                employeesRange = employeesRange[0];
                employeesRange = employeesRange.split('-');
                
                min = employeesRange[0];
                max = employeesRange[1];

            }

            const elementP = document.querySelectorAll( cwsnitcherosa.cwsnitcherosa_paragraph_selector );
            const elementI = document.querySelectorAll( cwsnitcherosa.cwsnitcherosa_image_selector );
            const favIcon  = document.querySelectorAll( cwsnitcherosa.cwsnitcherosa_favicon_selector );

            console.log(cwsnitcherosa, favIcon);

            if( elementP.length && domain && branch && employeesRange && cwsnitcherosa.cwsnitcherosa_paragraph_template ) {
                elementP[0].innerHTML = cwsnitcherosa.cwsnitcherosa_paragraph_template.replace( '%branch%', branch ).replace( '%minmax%', employeesRange );
            }

            if( elementP.length && domain && branch && min && max && cwsnitcherosa.cwsnitcherosa_paragraph_template ) {
                elementP[0].innerHTML = cwsnitcherosa.cwsnitcherosa_paragraph_template.replace( '%branch%', branch ).replace( '%minmax%', min + '-' + max );
            }

            if( elementI.length && domain ) {

                axios({
                    url: '/wp-json/cwsnitcherosa/v1/gdfi',
                    method: 'POST',
                    data: {
                        domain: domain,
                    },
                    headers: {
                        'X-WP-Nonce': cwsnitcherosa.nonce,
                    },
                }).then((response) => {

                    if( response.data.response ) {
                        elementI[0].src = response.data.response;
                    }

                });
                
            }

            if( favIcon.length && domain ) {

                axios({
                    url: '/wp-json/cwsnitcherosa/v1/gdfavico',
                    method: 'POST',
                    data: {
                        domain: domain,
                    },
                    headers: {
                        'X-WP-Nonce': cwsnitcherosa.nonce,
                    },
                }).then((response) => {

                    if( response.data.favicon ) {

                        for (let i = 0; i < favIcon.length; i++) {
                            favIcon[i].innerHTML = '<img src="' + response.data.favicon + '" alt="' + domain + '" />';
                        }

                    }

                });

            }
            
        }

    }
    
    // Spotter API settings
    window.SpotterSettings = {
        token: "1909|SYSvCYswUnAU5s2yNx71yWfQmuplwHbkCpOkbc3Ze5943a90",
        callback: snitcherCallback,
    };

    // if( favIcon.length && domain ) {

    // const domain = 'cloudweb.ch';

    //     axios({
    //         url: '/wp-json/cwsnitcherosa/v1/gdfavico',
    //         method: 'POST',
    //         data: {
    //             domain: domain,
    //         },
    //         headers: {
    //             'X-WP-Nonce': cwsnitcherosa.nonce,
    //         },
    //     }).then((response) => {

    //         if( response.data.favicon ) {
    //             const favIcon  = document.querySelectorAll( cwsnitcherosa.cwsnitcherosa_favicon_selector );
    //             if( favIcon.length ) {
    //                 for (let i = 0; i < favIcon.length; i++) {
    //                     favIcon[i].innerHTML = '<img src="' + response.data.favicon + '" alt="' + domain + '" />';
    //                 }
    //             }
    //             // favIcon.innerHTML = '<img src="' + response.data.favicon + '" alt="' + domain + '" />';
    //             console.log(favIcon);
    //             // elementI[0].src = response.data.response;
    //         }

    //     });

    // }

});